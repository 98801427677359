import React from 'react';

const LandingPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Header */}
      <header className="bg-blue-500 text-white py-6">
        <div className="container mx-auto px-4">
          <nav className="flex justify-between items-center">
            <h1 className="text-3xl font-bold">My Blog</h1>
            <ul className="flex space-x-6">
              <li className="hover:underline">Home</li>
              <li className="hover:underline">About</li>
              <li className="hover:underline">Contact</li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <div className="bg-blue-900 text-white py-24">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-5xl font-bold mb-6">Welcome to My Blog</h2>
            <p className="text-xl mb-8">
              Discover amazing stories and insights from passionate bloggers.
            </p>
            <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded">
              Get Started
            </button>
          </div>
        </div>
      </div>

      {/* Featured Blog Posts */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h3 className="text-3xl font-bold mb-10 text-center">Featured Blog Posts</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Blog Post Card */}
            <div className="bg-white p-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
              <h4 className="text-xl font-bold mb-4">The Art of Blogging</h4>
              <p className="text-gray-600 mb-2">By John Doe</p>
              <p className="text-gray-600 mb-4">August 4, 2023</p>
              <p className="text-gray-800">
                Learn the secrets of creating compelling blog content that engages your audience.
              </p>
            </div>

            {/* Blog Post Card */}
            <div className="bg-white p-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
              <h4 className="text-xl font-bold mb-4">Mastering SEO for Blogs</h4>
              <p className="text-gray-600 mb-2">By Jane Smith</p>
              <p className="text-gray-600 mb-4">August 3, 2023</p>
              <p className="text-gray-800">
                Optimize your blog posts to rank higher in search engine results and attract more readers.
              </p>
            </div>

            {/* Blog Post Card */}
            <div className="bg-white p-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
              <h4 className="text-xl font-bold mb-4">Designing Beautiful Blog Themes</h4>
              <p className="text-gray-600 mb-2">By Emily Johnson</p>
              <p className="text-gray-600 mb-4">August 2, 2023</p>
              <p className="text-gray-800">
                Learn how to create stunning blog themes that captivate your audience visually.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Newsletter Subscription */}
      <section className="bg-blue-500 text-white py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-md mx-auto text-center">
            <h3 className="text-3xl font-bold mb-6">Subscribe to Our Newsletter</h3>
            <p className="mb-6">Stay updated with the latest blog posts and news.</p>
            <form className="flex items-center justify-center">
              <input
                type="email"
                className="py-2 px-4 mr-4 rounded-l-lg focus:outline-none"
                placeholder="Enter your email address"
              />
              <button className="bg-blue-600 hover:bg-blue-700 py-2 px-6 rounded-r-lg font-semibold">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white text-center py-6">
        <p>&copy; {new Date().getFullYear()} My Blog. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
